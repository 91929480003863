import { authStore } from "@kaplan-labs/up-auth-api-client";

import { EXCLUDED_PAGES, MODAL_DISMISSED_KEY, SUCCESS_KEY } from "./constants";

export function markAsDismissed(modelID: string) {
  localStorage.setItem(modelID + MODAL_DISMISSED_KEY, "1");
}

export function markAsSuccess(modelID: string) {
  localStorage.setItem(modelID + SUCCESS_KEY, "1");
}

/*
  Do not display if:
  lead capture already a success,
  modal has been dismissed,
  user on excluded page,
  authenticated
*/
export function shouldDisplayModal(modalID: string) {
  if (document.querySelector(".modal-root")) {
    return false;
  }

  if (typeof localStorage === "undefined") {
    return false;
  }

  if (
    localStorage.getItem(modalID + MODAL_DISMISSED_KEY) ||
    localStorage.getItem(modalID + SUCCESS_KEY)
  ) {
    return false;
  }

  if (
    EXCLUDED_PAGES.some((item) => {
      return window.location.pathname.match(item);
    })
  ) {
    return false;
  }

  const $auth = authStore.get();
  if ($auth.currentUser) {
    return false;
  }
  return true;
}
