"use client";

import * as focusTrap from "focus-trap";
import { AnimatePresence, motion, useReducedMotion } from "framer-motion";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "usehooks-ts";

import { useAutoFocus } from "../use-autofocus";

import type { PropsWithChildren, ReactNode } from "react";

const MODAL_ID = "up-modal-title";

type Props = PropsWithChildren<{
  close: () => void;
  visible: boolean;
}>;

export function Modal({ children, close, visible }: Props): JSX.Element {
  const reducedMotion = useReducedMotion();
  const isDesktopScreen = useMediaQuery("(min-width: 1024px)");
  const trapRef = useRef<focusTrap.FocusTrap | null>(null);

  useEffect(() => {
    if (!visible) {
      trapRef.current?.deactivate?.();
      return;
    }
    setTimeout(() => {
      const onetrust = document.getElementById(
        "onetrust-consent-sdk",
      ) as HTMLElement;
      const modalRoot = document.querySelector(".modal-root") as HTMLElement;
      const heading = document.getElementById(MODAL_ID) as HTMLElement;
      if (modalRoot && heading) {
        trapRef.current = focusTrap.createFocusTrap([modalRoot, onetrust], {
          initialFocus: heading,
        });
        trapRef.current.activate();
      }
    }, 500);
  }, [visible]);

  return (
    <AnimatePresence>
      {visible ? (
        <div
          className="modal-root fixed inset-0"
          data-disable-document-scroll={visible}
          data-element="modal-root"
          aria-labelledby={MODAL_ID}
          role="dialog"
          aria-modal="true"
        >
          <motion.div
            className="fixed inset-0 z-[-1] bg-gray-800/60"
            key="modal-backdrop"
            data-element="modal-backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              ease: [0.87, 0, 0.13, 1],
              duration: reducedMotion ? 0 : 0.5,
            }}
          />

          <div
            className="h-full overflow-auto overscroll-contain px-2 pb-2 text-center before:inline-block before:h-full before:align-middle before:content-['']"
            data-element="modal-container"
            onClick={() => {
              trapRef.current?.deactivate();
              close?.();
            }}
          >
            <motion.div
              aria-modal="true"
              className="relative mt-10 inline-block w-full max-w-[650px] rounded-lg border-t border-t-gray-200 bg-white text-left align-bottom shadow drop-shadow-2xl md:my-8 lg:mb-8 lg:px-4 lg:align-middle"
              key="kup-modal"
              data-element="kup-modal"
              role="dialog"
              tabIndex={-1}
              onClick={(event) => {
                event.stopPropagation();
              }}
              initial={
                isDesktopScreen
                  ? { opacity: 0, y: -100 }
                  : { opacity: 0, y: 200 }
              }
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{
                ease: [0.87, 0, 0.13, 1],
                duration: reducedMotion ? 0 : 1,
              }}
            >
              {children}
            </motion.div>
          </div>
        </div>
      ) : null}
    </AnimatePresence>
  );
}

export function ModalContent({
  children,
  Heading,
}: PropsWithChildren<{
  Heading?: (headingProps: Record<string, unknown>) => ReactNode;
}>): JSX.Element {
  const heading = useAutoFocus();

  return (
    <div className="overflow-hidden p-4 pt-14 pb-6 md:px-5 md:py-9 lg:pt-16">
      {Heading ? Heading({ id: MODAL_ID, ref: heading, tabIndex: -1 }) : null}
      {children}
    </div>
  );
}

export const modalHeaderProps = {
  id: MODAL_ID,
};
