import { Controller } from "react-hook-form";

import {
  ErrorMessage,
  HelpText,
  InputCheckbox,
  InputRadio,
  InputText,
  Label,
  LabelCheckbox,
  LabelRadio,
  RenderElement,
  Select,
  createValidation,
  getErrorMessage,
} from "@up/form";
import { Spinner } from "@up/ui";

import { FetchCatalogueItems } from "./FetchCatalogueItems";

import type { DataTypes } from "@up/form";
import type { ReactNode } from "react";
import type { FieldErrors, UseFormRegister } from "react-hook-form";

type FormElementProps = {
  formErrors: FieldErrors;
  formValues: DataTypes.FormValues;
  register: UseFormRegister<any>;
};

type SelectProps = {
  control: any;
  formErrors: FieldErrors;
  formValues: DataTypes.FormValues;
  register: UseFormRegister<any>;
};

type Props = {
  [x: PropertyKey]: any;
  children: ReactNode;
  className?: string;
  hasErrors?: boolean;
  hidden?: boolean;
};

export type UserType = "" | "Student" | "Guardian" | "Counselor";

function ElementWrapper({
  children,
  className = "",
  hasErrors = false,
  hidden = false,
  ...rest
}: Props): JSX.Element | null {
  if (hidden) {
    return null;
  }

  return (
    <div
      className={`kup-element ${className} ${
        hasErrors ? "kup-element-has-errors" : ""
      }`}
      {...rest}
    >
      {children}
    </div>
  );
}

export const Type = function ({
  control,
  formErrors,
  formValues,
  register,
}: SelectProps) {
  const KEY = "type";
  const VALIDATION = { presence: true };
  const OPTIONS: Array<{
    value: UserType;
    display: string;
  }> = [
    {
      value: "Student",
      display: "Student",
    },
    {
      value: "Guardian",
      display: "Parent/Guardian",
    },
    {
      value: "Counselor",
      display: "School Counselor/Teacher",
    },
  ];

  return (
    <RenderElement
      control={control}
      element={{
        _id: KEY,
        type: "select",
        name: KEY,
        label: "I am a...",
        displayKey: "display",
        valueKey: "value",
        options: OPTIONS,
        validate: VALIDATION,
      }}
      errors={formErrors}
      formValues={formValues}
      register={register}
    />
  );
};

export const Age = function ({
  control,
  formErrors,
  formValues,
  register,
}: SelectProps) {
  const KEY = "age";
  const VALIDATION = { presence: true };
  const ERRORS = formErrors[KEY];
  const OPTIONS = [
    {
      value: 13,
      key: "13",
    },
    {
      value: 14,
      key: "14",
    },
    {
      value: 15,
      key: "15",
    },
    {
      value: 16,
      key: "16",
    },
    {
      value: 17,
      key: "17",
    },
    {
      value: 18,
      key: "18+",
    },
  ];

  return (
    <ElementWrapper hasErrors={!!ERRORS}>
      <Label htmlFor={KEY} required text="Age" />
      <Controller
        defaultValue=""
        control={control}
        name="age"
        render={({ field }) => {
          return (
            <Select
              data={OPTIONS}
              displayKey="key"
              hasErrors={!!ERRORS}
              name="age"
              placeholderText="Please select"
              registerProps={register(KEY, {
                valueAsNumber: true,
                ...createValidation(VALIDATION, formValues),
              })}
              valueKey="value"
            />
          );
        }}
      />
      <ErrorMessage
        text={
          ERRORS
            ? getErrorMessage(ERRORS.type as unknown as string, VALIDATION)
            : null
        }
      />
    </ElementWrapper>
  );
};

export const Email = function ({
  formErrors,
  formValues,
  register,
}: FormElementProps) {
  const KEY = "email";
  const VALIDATION = { email: true, presence: true };
  const ERRORS = formErrors[KEY];

  return (
    <ElementWrapper hasErrors={!!ERRORS}>
      <Label htmlFor={KEY} required text="Email" />
      <InputText
        hasErrors={Boolean(ERRORS)}
        registerProps={register(KEY, createValidation(VALIDATION, formValues))}
      />
      <ErrorMessage
        text={
          ERRORS
            ? getErrorMessage(ERRORS.type as unknown as string, VALIDATION)
            : null
        }
      />
    </ElementWrapper>
  );
};

export const FirstName = function ({
  formErrors,
  formValues,
  register,
}: FormElementProps) {
  const KEY = "firstName";
  const VALIDATION = { presence: true };
  const ERRORS = formErrors[KEY];

  return (
    <ElementWrapper hasErrors={!!ERRORS}>
      <Label htmlFor={KEY} required={true} text="First Name" />
      <InputText
        hasErrors={Boolean(ERRORS)}
        registerProps={register(KEY, createValidation(VALIDATION, formValues))}
      />
      <ErrorMessage
        text={
          ERRORS
            ? getErrorMessage(ERRORS.type as unknown as string, VALIDATION)
            : null
        }
      />
    </ElementWrapper>
  );
};

export const LastName = function ({
  formErrors,
  formValues,
  register,
}: FormElementProps) {
  const KEY = "lastName";
  const VALIDATION = { presence: true };
  const ERRORS = formErrors[KEY];

  return (
    <ElementWrapper hasErrors={!!ERRORS}>
      <Label htmlFor={KEY} required={true} text="Last Name" />
      <InputText
        hasErrors={Boolean(ERRORS)}
        registerProps={register(KEY, createValidation(VALIDATION, formValues))}
      />
      <ErrorMessage
        text={
          ERRORS
            ? getErrorMessage(ERRORS.type as unknown as string, VALIDATION)
            : null
        }
      />
    </ElementWrapper>
  );
};

export const GuardianEmail = function ({
  formErrors,
  formValues,
  register,
  control,
}: {
  control: any;
  formErrors: FieldErrors;
  formValues: DataTypes.FormValues;
  register: UseFormRegister<any>;
}) {
  const KEY = "guardianEmail";
  const VALIDATION = {
    email: true,
    uniqueTo: {
      from: "email",
      errorMessage:
        "Parent/guardian email must be different from student email",
    },
  };
  const ERRORS = formErrors[KEY];
  const registerProps = register(KEY, createValidation(VALIDATION, formValues));

  return (
    <ElementWrapper hasErrors={!!ERRORS}>
      <Label htmlFor={KEY} text="Parent/Guardian Email (optional)" />
      <Controller
        defaultValue=""
        control={control}
        name={KEY}
        render={({ field }) => {
          return (
            <input
              aria-invalid={Boolean(ERRORS)}
              className="kup-input-text"
              id={registerProps["name"]}
              type="text"
              {...registerProps}
            />
          );
        }}
      />
      <ErrorMessage
        text={
          ERRORS
            ? getErrorMessage(ERRORS.type as unknown as string, VALIDATION)
            : null
        }
      />
    </ElementWrapper>
  );
};

type TopicSelectionProps = FormElementProps & {
  topics: string[];
};
export const TopicSelection = function ({
  formErrors,
  register,
  topics,
}: TopicSelectionProps) {
  const KEY = "topics";
  const ERRORS = formErrors[KEY];

  return (
    <ElementWrapper hasErrors={!!ERRORS}>
      <HelpText>Select the topics(s) you are interested in:</HelpText>
      <div className="grid grid-cols-1 gap-2">
        {topics.map((topic) => {
          return (
            <div key={topic}>
              <LabelCheckbox text={topic}>
                <InputCheckbox
                  index={topic}
                  value={topic}
                  registerProps={register(KEY)}
                />
              </LabelCheckbox>
            </div>
          );
        })}
      </div>
    </ElementWrapper>
  );
};

type ProgramSelectionProps = FormElementProps & {
  baseURL: string;
  partnerID: string;
};
export const ProgramSelection = function ({
  baseURL,
  formErrors,
  formValues,
  partnerID,
  register,
}: ProgramSelectionProps) {
  const KEY = "programs";
  const ERRORS = formErrors[KEY];

  return (
    <ElementWrapper hasErrors={!!ERRORS}>
      <HelpText>Select the program(s) you are interested in:</HelpText>
      <div className="grid grid-cols-1 gap-2">
        <FetchCatalogueItems baseURL={baseURL} partnerID={partnerID}>
          {({ didError, isLoading, catalogueItems }) => {
            if (isLoading) {
              return (
                <div className="mb-6 flex items-center">
                  <Spinner className="mr-3" /> Loading Programs
                </div>
              );
            } else if (didError) {
              return <div>There was an error loading programs</div>;
            } else if (catalogueItems.length) {
              return catalogueItems.map((option) => {
                return (
                  <div key={option._id}>
                    <LabelCheckbox text={option.name}>
                      <InputCheckbox
                        index={option._id}
                        value={option._id}
                        registerProps={register(KEY)}
                      />
                    </LabelCheckbox>
                  </div>
                );
              });
            } else {
              return <div />;
            }
          }}
        </FetchCatalogueItems>
      </div>
    </ElementWrapper>
  );
};

type TOUPPAcceptProps = {
  formErrors: FieldErrors;
  formValues: DataTypes.FormValues;
  partnerName: string;
  register: UseFormRegister<any>;
};
export function TOUPPAccept({
  formErrors,
  formValues,
  partnerName,
  register,
}: TOUPPAcceptProps) {
  const KEY = "touppAccept";
  const VALIDATION = { presence: true };
  const ERRORS = formErrors[KEY];

  return (
    <ElementWrapper hasErrors={!!ERRORS}>
      <LabelCheckbox
        Label={
          <>
            I accept the {partnerName}{" "}
            <a
              className="kup-link"
              href="/terms-of-use"
              rel="noreferrer noopener"
              target="_blank"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              className="kup-link"
              href="/privacy-policy"
              rel="noreferrer noopener"
              target="_blank"
            >
              Privacy Policy
            </a>{" "}
            (
            <a
              className="kup-link"
              href="/privacy-policy-addendum-for-california-residents"
              rel="noreferrer noopener"
              target="_blank"
            >
              CA Notice At Collection
            </a>
            )
          </>
        }
        required
      >
        <input
          type="checkbox"
          value="true"
          {...register(KEY, createValidation(VALIDATION, formValues))}
        />
      </LabelCheckbox>
      <ErrorMessage
        text={
          ERRORS
            ? getErrorMessage(ERRORS.type as unknown as string, VALIDATION)
            : null
        }
      />
    </ElementWrapper>
  );
}

type CrossPartnerMarketingOptInProps = {
  register: UseFormRegister<any>;
};
export function CrossPartnerMarketingOptIn({
  register,
}: CrossPartnerMarketingOptInProps) {
  const KEY = "crossPartnerMarketingOptIn";

  return (
    <ElementWrapper>
      <HelpText>
        Our technology partner Kaplan University Partners also works with other
        universities and organizations to deliver education programs. Would you
        like to learn about other programs?
      </HelpText>
      <LabelCheckbox
        Label={
          <>
            Yes, I would like to learn about other programs and consent to be
            contacted about them.
          </>
        }
      >
        <input type="checkbox" value="true" {...register(KEY)} />
      </LabelCheckbox>
    </ElementWrapper>
  );
}

export function EuResident({
  formErrors,
  formValues,
  register,
}: FormElementProps) {
  const KEY = "euResident";
  const VALIDATION = { presence: true };
  const ERRORS = formErrors[KEY];

  return (
    <ElementWrapper hasErrors={!!ERRORS}>
      <Label required={true}>
        I am located in the European Union or United Kingdom
      </Label>
      <div className="flex flex-row gap-6">
        <div>
          <LabelRadio text="Yes">
            <InputRadio
              registerProps={register(
                KEY,
                createValidation(VALIDATION, formValues),
              )}
              value="true"
            />
          </LabelRadio>
        </div>
        <div>
          <LabelRadio text="No">
            <InputRadio
              registerProps={register(
                KEY,
                createValidation(VALIDATION, formValues),
              )}
              value="false"
            />
          </LabelRadio>
        </div>
      </div>
      <ErrorMessage
        text={
          ERRORS
            ? getErrorMessage(ERRORS.type as unknown as string, VALIDATION)
            : null
        }
      />
    </ElementWrapper>
  );
}

type KaplanEUPartnerProps = {
  partnerName: string;
  formErrors: FieldErrors;
  register: UseFormRegister<any>;
};
export function KaplanEUPartnerAccept({
  partnerName,
  formErrors,
  register,
}: KaplanEUPartnerProps) {
  const KEY = "euEmailConsent";
  const ERRORS = formErrors[KEY];

  return (
    <ElementWrapper hasErrors={!!ERRORS}>
      <LabelCheckbox
        Label={
          <>
            I consent to be contacted about {partnerName}&apos;s education
            programs and services. Messages are sent by {partnerName}&apos;s
            technology partner Kaplan University Partners.
          </>
        }
      >
        <input type="checkbox" value="true" {...register(KEY)} />
      </LabelCheckbox>
    </ElementWrapper>
  );
}

export function CanadianResident({
  formErrors,
  formValues,
  register,
}: FormElementProps) {
  const KEY = "canResident";
  const VALIDATION = { presence: true };
  const ERRORS = formErrors[KEY];

  return (
    <ElementWrapper hasErrors={!!ERRORS}>
      <Label required={true}>I am a Canadian resident</Label>
      <div className="flex flex-row gap-6">
        <div>
          <LabelRadio text="Yes">
            <InputRadio
              registerProps={register(
                KEY,
                createValidation(VALIDATION, formValues),
              )}
              value="true"
            />
          </LabelRadio>
        </div>
        <div>
          <LabelRadio text="No">
            <InputRadio
              registerProps={register(
                KEY,
                createValidation(VALIDATION, formValues),
              )}
              value="false"
            />
          </LabelRadio>
        </div>
      </div>
      <ErrorMessage
        text={
          ERRORS
            ? getErrorMessage(ERRORS.type as unknown as string, VALIDATION)
            : null
        }
      />
    </ElementWrapper>
  );
}

type KaplanCanadaPartnerProps = {
  partnerName: string;
  formErrors: FieldErrors;
  register: UseFormRegister<any>;
};
export function KaplanCanadaPartnerAccept({
  partnerName,
  formErrors,
  register,
}: KaplanCanadaPartnerProps) {
  const KEY = "canEmailConsent";
  const ERRORS = formErrors[KEY];

  return (
    <ElementWrapper hasErrors={!!ERRORS}>
      <LabelCheckbox
        Label={
          <>
            I consent to receive commercial email messages from {partnerName}{" "}
            about its education programs and services. You can withdraw your
            consent at any time by contacting us: Kaplan University Partners,
            1515 West Cypress Creek Road, Fort Lauderdale, FL 33309; email
            privacy@precollegeprograms.org.
          </>
        }
      >
        <input type="checkbox" value="true" {...register(KEY)} />
      </LabelCheckbox>
    </ElementWrapper>
  );
}

export function Under16MarketingConsent({
  register,
}: {
  label?: string;
  register: UseFormRegister<any>;
}) {
  const KEY = "underageConsent";

  return (
    <ElementWrapper>
      <LabelCheckbox
        Label={
          <div>
            I agree to receiving marketing emails and communication. I
            understand that I may unsubscribe at any time.
          </div>
        }
      >
        <InputCheckbox
          value="true"
          registerProps={{
            ...register(KEY),
          }}
        />
      </LabelCheckbox>
    </ElementWrapper>
  );
}
