"use client";

import { datadogLogs } from "@datadog/browser-logs";
import { useLogin } from "@kaplan-labs/up-auth-api-client";
import { PaperPlaneTilt, Question } from "@phosphor-icons/react";
import { HTTPError } from "ky";
import { ErrorBoundary } from "react-error-boundary";

import { useTheme } from "@up/data";
import { Label } from "@up/form";

import { BoundryFallback } from "../boundry-fallback";
import { Spinner } from "../spinner/spinner";
import { UPLink } from "../up-link";
import { useAutoFocus } from "../use-autofocus";

import type { ErrorInfo, RefObject } from "react";

const formatOTPErrorMessage = function (message: string) {
  if (message.match(/401/)) {
    return "Invalid code. Please try again";
  } else {
    return message;
  }
};

const logBoundryError = (error: Error, info: ErrorInfo) => {
  datadogLogs.logger.error(
    "Auth Boundry Error",
    { up_stack: info.componentStack },
    error,
  );
};

type Props = {
  cancel?: () => void;
  email?: string;
  onSuccess?: () => void;
};

function Go({ cancel, email = "", onSuccess }: Props) {
  const theme = useTheme();
  const heading = useAutoFocus();

  const {
    activeField,
    email: emailValue,
    emailErrorMessage,
    otpErrorMessage,
    onSubmit,
    isLoading,
    onChange,
    otp,
    reset,
  } = useLogin({
    initialState: {
      email,
      otpMetadata: {
        partnerId: process.env[
          "NEXT_PUBLIC_HALL_OF_RECORDS_PARTNER_ID"
        ] as string,
      },
    },
    onSuccess() {
      onSuccess?.();
    },
    onException(error) {
      if (error instanceof HTTPError) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          datadogLogs.logger.error(
            "Auth HTTP Error",
            {
              up_status: error.response.status,
            },
            error,
          );
          return;
        }
      }

      datadogLogs.logger.error("Auth Error", {}, error);
    },
  });

  return (
    <form onSubmit={onSubmit}>
      {activeField === "email" && (
        <>
          <h2
            className={theme.heading2Class}
            tabIndex={-1}
            ref={heading as RefObject<HTMLHeadingElement>}
          >
            Verify your email
          </h2>
          <p className="mb-8 opacity-80 leading-snug">
            In order to keep your information safe, enter your email to receive
            a unique, single-use password.
          </p>
          <div>
            <Label
              htmlFor="email"
              style={{ display: "block" }}
              text="Email Address"
            />
            <input
              className="kup-input-text"
              id="email"
              name="email"
              onChange={onChange}
              type="email"
              value={emailValue}
            />
            <br />
          </div>
        </>
      )}
      {activeField === "otp" && (
        <div>
          <h2
            className={theme.heading2Class}
            tabIndex={-1}
            ref={heading as RefObject<HTMLHeadingElement>}
          >
            Single-use password sent to:
          </h2>
          <p className="text-lg text-ellipsis overflow-hidden">{emailValue}</p>

          <br />

          <div>
            <label className="mb-1 block" htmlFor="otp">
              Single-use password
            </label>
            <input
              autoComplete="off"
              className="kup-input-text  kup-font-monospace block w-full text-center"
              id="otp"
              name="otp"
              onChange={onChange}
              style={{
                width: "12rem",
                fontSize: "1.5rem",
              }}
              type="text"
              value={otp}
            />
          </div>

          <br />
          {otpErrorMessage ? (
            <p className="mb-4" style={{ color: "#d00" }}>
              {formatOTPErrorMessage(otpErrorMessage)}
            </p>
          ) : null}
        </div>
      )}

      {emailErrorMessage ? (
        <p className="mb-4 leading-snug">
          <span className="text-red-500 font-bold">{emailErrorMessage}.</span>
          <br />
          If you need assistance please contact us at{" "}
          <a className="underline" href={`mailto:${theme.supportEmail}`}>
            {theme.supportEmail}
          </a>
        </p>
      ) : null}

      {activeField === "email" ? (
        <p className="mb-5 opacity-70 text-sm leading-tight">
          By proceeding, I authorize Kaplan, Inc. as {theme.partnerName}
          's technology partner to collect, store, use, transfer, share,
          disclose or process in the United States my personal data including,
          sensitive personal information if volunteered by me. See our{" "}
          <UPLink className="underline" href="/terms-of-use" target="_blank">
            Terms of Use
          </UPLink>{" "}
          and{" "}
          <UPLink className="underline" href="/privacy-policy" target="_blank">
            Privacy Policy
          </UPLink>{" "}
          (
          <UPLink
            className="underline"
            href="/privacy-policy-addendum-for-california-residents"
            target="_blank"
          >
            CA Notice At Collection
          </UPLink>
          ).
        </p>
      ) : null}

      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <button
            className={`${theme.buttonPrimaryClass} up-index-hero-apply-now-button`}
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              opacity: isLoading ? 0.8 : 1,
            }}
            type="submit"
          >
            {activeField === "otp" && "Submit Code"}
            {activeField === "email" && "Submit Email"}
          </button>
          {isLoading && <Spinner className="ml-3" />}
        </div>

        {cancel ? (
          <button
            className="px-2 underline"
            disabled={isLoading}
            onClick={cancel}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              opacity: isLoading ? 0.8 : 1,
            }}
          >
            Cancel
          </button>
        ) : null}
      </div>

      {activeField === "otp" ? (
        <div className="animation-fade-in mt-7 pt-6 mb-6 flex flex-col gap-5 leading-snug border-t border-neutral-400">
          <div className="flex items-center gap-4 opacity-80">
            <div className="shink-0">
              <PaperPlaneTilt size={24} color="#000" />
            </div>
            <div className="overflow-hidden">
              <p>
                <strong>Email will be sent from:</strong>
              </p>
              <p className="text-ellipsis overflow-hidden">
                {theme.supportEmail}
              </p>
            </div>
          </div>

          <div className="flex items-center gap-4 opacity-80">
            <div className="shink-0">
              <Question size={24} color="#000" />
            </div>
            <p>
              <strong>Don't see the email?</strong>
              <br />
              Check your spam and filtered folders. Having trouble?{" "}
              <button
                className="m-0 p-0 underline"
                onClick={reset}
                type="button"
              >
                Re-Enter Your Email
              </button>
            </p>
          </div>
        </div>
      ) : null}
      <br />
      <br />
    </form>
  );
}

export function Auth(props: Props) {
  return (
    <ErrorBoundary fallbackRender={BoundryFallback} onError={logBoundryError}>
      <Go {...props} />
    </ErrorBoundary>
  );
}
